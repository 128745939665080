import analytics, { Events } from 'modules/Analytics';
import colors from 'styles/colors';
import FilledButton from 'primitives/Buttons/FilledButton';
import H3 from 'primitives/Typography/Headings/H3';
import isValidEmail from 'utils/validate/isValidEmail';
import LoadingSpinner from 'components/LoadingSpinner';
import NavLink from 'components/NavLink';
import paths from 'router/Routes/paths';
import qs from 'qs';
import Recurly, {
  CheckoutButtonWrap,
  DefaultObj,
  ErrorMessage,
  FormInnerWrap,
  HeroContainer,
  HeroStyles,
  HeroText,
  InputField,
  InputRow,
  Logo,
  PlanCodes,
  RecurlyField,
  RecurlyFormWrap,
  SuccessMessage,
  TermsofUseText,
} from 'components/Recurly';
import RecurlyHOC from 'components/Recurly/RecurlyHOC';
import SpinnerWrapper from './primitives/SpinnerWrapper';
import TermsLink from './primitives/TermsLink';
import TermsModal from 'components/TermsModal';
import transport from 'api/transport';
import useModal from 'hooks/useModal';
import useMount from 'hooks/useMount';
import {
  ChangeEvent,
  FormEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CheckBox } from 'components/FormFields';
import { IGetTranslateFunctionResponse } from 'redux-i18n';
import { createV3 as recaptchaCreate } from 'client/js/services/Recaptcha';
import { RECURLY_PLAN_CODE_MAP } from 'constants/subscriptionConstants';
import { sendRecurlyPurchase } from './services';

type Props = {
  accountEmail: string;
  ampUrl: string;
  isAnonymous: boolean;
  isTrial: boolean;
  profileId: string;
  openLogin: () => void;
  recurlyKey: string;
  sessionId: string;
  subscription: string;
  translate: IGetTranslateFunctionResponse;
};

const RecurlySubscribe: FunctionComponent<Props> = ({
  accountEmail = '',
  ampUrl,
  isAnonymous,
  isTrial,
  profileId,
  openLogin,
  recurlyKey,
  sessionId,
  subscription,
  translate,
}: Props) => {
  const [inputErrors, setInputErrors] = useState<DefaultObj>({});
  const [loading, setLoading] = useState(false);
  const [planCode, setPlanCode] = useState<PlanCodes | ''>('');
  const [price, setPrice] = useState('');
  const [success] = useState<DefaultObj>({});
  const [successfulTransaction, setSuccessfulTransaction] = useState(false);
  const [upsellFrom, setUpsellFrom] = useState('');
  const [userEmail, setUserEmail] = useState(accountEmail);
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userZipCode, setUserZipCode] = useState('');
  const [autoRenew, setAutoRenew] = useState(false);
  const [errorRef, setErrorRef] = useState<HTMLDivElement | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [Modal, toggle] = useModal();

  const configureRecurly = async (code: string, key: string) => {
    if (__CLIENT__) {
      await Recurly.load();
      await Recurly.configure({
        recurlyKey: key,
      });

      const recurlyPrice = await Recurly.price({ planCode: code });
      setPrice(`$${recurlyPrice}`);
    }
  };

  useMount(() => {
    let code: PlanCodes;
    let planCodeAvailable = false;

    const cb = () => {
      configureRecurly(code, recurlyKey);
    };

    if (isAnonymous) {
      openLogin();
    }

    if (!recurlyKey && __CLIENT__) {
      window.location.href = '/404';
    }

    if (__CLIENT__) {
      recaptchaCreate();

      const { subscriptionId, upsellFrom: upsell = false } = qs.parse(
        window.location.search,
        {
          ignoreQueryPrefix: true,
        },
      );

      code = subscriptionId;

      planCodeAvailable =
        !!code && Object.keys(RECURLY_PLAN_CODE_MAP).indexOf(code) > -1;

      if (!planCodeAvailable) {
        const upsellParam = upsell ? `?upsellFrom=${upsell}` : '';
        window.location.href = `${paths.recurly.upgrade}${upsellParam}`;
      }

      if (planCodeAvailable) {
        if (analytics.trackPaymentOpen) {
          Recurly.analyticsOpen({ planCode: code });
        }

        setPlanCode(code);
        setUpsellFrom(upsell);
        configureRecurly(code, recurlyKey);
      }

      window.addEventListener('resize', cb);
    }

    return () => {
      if (!successfulTransaction) {
        Recurly.analyticsExit();
      }

      if (__CLIENT__) {
        window.removeEventListener('resize', cb);
      }
    };
  });

  useEffect(() => {
    if (subscription.toLowerCase() === planCode) {
      const upsellParam = upsellFrom ? `?upsellFrom=${upsellFrom}` : '';
      window.location.href = `${paths.recurly.upgrade}${upsellParam}`;
    }
  }, [planCode, subscription, upsellFrom]);

  useEffect(() => {
    if (errorRef && inputErrors?.mainMessage) {
      errorRef.focus();
    }
  }, [errorRef, inputErrors]);

  useEffect(() => {
    // Vermont auto renewal law requires users to opt-in to annual recurring subscriptions.
    // We default auto-renew to false for annual and true for all others
    setAutoRenew(!!(planCode && planCode !== 'all_access_annual'));
  }, [planCode]);

  const purchase = (token: string) => {
    window.grecaptcha.ready(async () => {
      // TODO: IHRWEB-16371 - This site key was requested to be hardcoded within this file while transitioning to
      // v3 of recaptcha, once fully transitioned over, this can be extracted out of the file.
      const validationToken = await window.grecaptcha.execute(
        '6LesRw8aAAAAAGsr2tE_-Gwuf30wq_tehiVfvt2r',
        {
          action: 'submit',
        },
      );

      try {
        // TODO: IHRWEB-16371 - Recaptcha may return errors, which will be returned by AMP transport call here
        // once they finish their work (IHRAMP-7868), we need to revisit here to ensure there aren't any potential errors
        // to be mapped to error state, if not, this is safe to remove.
        await transport(
          sendRecurlyPurchase({
            ampUrl,
            autoRenew,
            billingToken: token,
            email: userEmail,
            validationToken,
            firstName: userFirstName,
            lastName: userLastName,
            planCode,
            profileId,
            sessionId,
          }),
        );
        setLoading(false);
        setSuccessfulTransaction(true);

        const queryStringTransfer = upsellFrom
          ? `&upsellFrom=${upsellFrom}`
          : '';
        window.location.href = `${paths.recurly.confirmation}?subscriptionId=${planCode}${queryStringTransfer}`;
      } catch (error: any) {
        const fraudCodes = ['VT_INVALID', 'VT_BELOW_THRESHOLD', 'VT_EXPIRED'];
        const symbol = error?.response?.data?.symbol ?? '';
        let errorMsg;
        if (fraudCodes.includes(symbol)) {
          const helpLink = (
            <NavLink to="https://help.iheart.com">help.iheart.com</NavLink>
          );
          errorMsg = translate(
            `Our system has flagged this transaction as fraudulent. If you consider this to be an error, please contact us through {helpLink} and we would be happy to help.`,
            { helpLink },
          );
        } else {
          errorMsg =
            error?.response?.data?.error ??
            error?.response?.data?.errors?.[0]?.message ??
            translate('There was an error validating your request.');
        }
        const errorObj: DefaultObj = {
          mainMessage: errorMsg,
        };

        analytics.track(Events.Error, {
          error: {
            errorMessage: errorObj.mainMessage,
            errorType: Events.FailedSubscription,
          },
        });

        setInputErrors(errorObj);
        setLoading(false);
        setSuccessfulTransaction(false);
      }
    });
  };

  const validateForm = () => {
    const msgs: { [key: string]: string } = {
      email: isValidEmail(userEmail)
        ? ''
        : translate('Please enter a valid email address'),
      first_name: userFirstName ? '' : translate(`First Name can't be blank`),
      last_name: userLastName ? '' : translate(`Last Name can't be blank`),
      postal_code: userZipCode
        ? ''
        : translate(`Zip / Postal Code can't be blank`),
    };
    return {
      ...msgs,
      messages: Object.keys(msgs)
        .filter(key => !!msgs[key])
        .map(key => <li key={key}>{msgs[key]}</li>),
    };
  };

  const generateToken = async (e: FormEvent<EventTarget>): Promise<any> => {
    if (!__CLIENT__) {
      return;
    }

    e.preventDefault();

    if (isAnonymous) {
      openLogin();
      return;
    }

    if (!loading) {
      setLoading(true);
      setInputErrors({});

      const clientsideErrors = validateForm();
      if (clientsideErrors.messages.length) {
        setInputErrors({
          ...clientsideErrors,
          mainMessage: translate('Please fix the following errors:'),
        });
        setLoading(false);
        return;
      }

      const { errors: tokenErrors, token } = await Recurly.token(
        formRef.current,
      );

      if (tokenErrors) {
        analytics.track(Events.Error, {
          error: {
            errorMessage: inputErrors.mainMessage,
            errorType: Events.FailedSubscription,
          },
        });

        const messages = Object.keys(tokenErrors)
          .filter(key => key !== 'mainMessage' && !!tokenErrors[key])
          .map(key => (
            <li key={key}>
              {`${key[0].toUpperCase()}${key.slice(1).replace('_', ' ')} ${
                tokenErrors[key]
              }`}
            </li>
          ));

        setInputErrors({
          ...(tokenErrors ?? {}),
          messages,
          mainMessage: translate('There was an error validating your request.'),
        });
        setLoading(false);
        return;
      }

      if (token) {
        setInputErrors({});
        purchase(token);
        setLoading(false);
      }
    }
  };

  const openOfferModal = () => {
    toggle();
  };

  const updateEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value !== userEmail) setUserEmail(e?.target?.value ?? '');
  };
  const updateFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value !== userFirstName)
      setUserFirstName(e?.target?.value ?? '');
  };
  const updateLastName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value !== userLastName)
      setUserLastName(e?.target?.value ?? '');
  };
  const updateZipCode = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value !== userZipCode)
      setUserZipCode(e?.target?.value ?? '');
  };
  const updateAutoRenew = (_name: string, checked: boolean) => {
    if (checked !== autoRenew) setAutoRenew(checked);
  };

  let heroText =
    subscription !== 'FREE' && planCode
      ? translate(`Switch to iHeart {plan}`, {
          plan: RECURLY_PLAN_CODE_MAP[planCode],
        })
      : translate('Start Your Free 30 Day Trial');

  let explainText = '';

  if (subscription === 'FREE' && planCode) {
    explainText = translate(
      `After the free 30 day iHeart {plan} trial,
    you will be charged US {price} (plus applicable taxes) monthly.
    Cancel Anytime.`,
      { plan: RECURLY_PLAN_CODE_MAP[planCode], price },
    );
  }

  if (subscription !== 'FREE' && !isTrial) {
    explainText = translate(
      `You can cancel anytime. You will be
    charged US {price}, plus applicable taxes per month.`,
      { price },
    );
  }

  if (planCode === 'all_access_family') {
    heroText = translate('Start Your Family Plan');
    explainText = translate(
      `Once you start your iHeart {plan} Plan, you will be charged US $14.99 (plus applicable taxes) monthly. Cancel anytime.`,
      { plan: RECURLY_PLAN_CODE_MAP[planCode] },
    );
  }

  if (planCode === 'all_access_annual') {
    heroText = translate('Sign up for iHeart All Access Annual Plan');
    explainText = translate(
      `After signing up, you will be charged US {price} plus applicable taxes and your first year of iHeart {plan} will start. If you opt in for automatic renewal then you will be charged again on this date every year as the subscription renews. Cancel anytime.`,
      { plan: RECURLY_PLAN_CODE_MAP[planCode], price },
    );
  }

  if (subscription !== 'FREE' && isTrial && planCode) {
    explainText = translate(
      `After the free iHeart {plan} trial,  you will be charged US {price}, (plus applicable taxes) monthly.
    Cancel Anytime.`,
      { plan: RECURLY_PLAN_CODE_MAP[planCode], price },
    );
  }

  return (
    <>
      <RecurlyHOC>
        {!__CLIENT__ ? Recurly.helmet() : null}

        <HeroContainer transformValue="-13rem">
          <HeroStyles>
            <Logo />
            <HeroText>{heroText}</HeroText>
          </HeroStyles>
        </HeroContainer>

        <RecurlyFormWrap>
          <H3>{explainText}</H3>

          <p className="offerLink">
            {translate('View')}{' '}
            <TermsLink onClick={openOfferModal}>
              {translate('Offer Terms')}
            </TermsLink>
          </p>

          {success.message ? (
            <SuccessMessage>{success.message}</SuccessMessage>
          ) : null}

          {inputErrors.mainMessage ? (
            <ErrorMessage
              data-test="error-message"
              ref={ref => setErrorRef(ref)}
              tabIndex={0}
            >
              {inputErrors.mainMessage}
              {inputErrors?.messages?.length ? (
                <ul>{inputErrors.messages}</ul>
              ) : null}
            </ErrorMessage>
          ) : null}

          <form
            data-test="checkoutForm"
            onSubmit={e => generateToken(e)}
            ref={formRef}
          >
            <FormInnerWrap>
              <InputRow full>
                <div>
                  <label>{translate('Email Address')}</label>
                  <InputField
                    className={inputErrors.email ? 'error' : ''}
                    data-recurly="email"
                    data-test="recurly-email"
                    disabled={loading}
                    last
                    onChange={e => updateEmail(e)}
                    placeholder={translate('Enter email address')}
                    type="text"
                    value={userEmail}
                  />
                </div>
              </InputRow>
              <InputRow>
                <div>
                  <label>{translate('First Name')}</label>
                  <InputField
                    className={inputErrors.first_name ? 'error' : ''}
                    data-recurly="first_name"
                    data-test="recurly-first_name"
                    disabled={loading}
                    onChange={e => updateFirstName(e)}
                    placeholder={translate('First Name')}
                    type="text"
                    value={userFirstName}
                  />
                </div>
                <div>
                  <label>{translate('Last Name')}</label>
                  <InputField
                    className={inputErrors.last_name ? 'error' : ''}
                    data-recurly="last_name"
                    data-test="recurly-last_name"
                    disabled={loading}
                    last
                    onChange={e => updateLastName(e)}
                    placeholder={translate('Last Name')}
                    value={userLastName}
                  />
                  <InputField
                    data-recurly="country"
                    placeholder="Country"
                    type="hidden"
                    value="US"
                  />
                </div>
              </InputRow>
              <InputRow full>
                <div>
                  <label>{translate('Card Details')}</label>
                  <RecurlyField
                    className={
                      inputErrors.number ||
                      inputErrors.cvv ||
                      inputErrors.month ||
                      inputErrors.year
                        ? 'error'
                        : ''
                    }
                    data-recurly="card"
                    loading={loading}
                  />
                </div>
              </InputRow>
              <InputRow>
                <div>
                  <label>{translate('Zip / Postal Code')}</label>
                  <InputField
                    className={inputErrors.postal_code ? 'error' : ''}
                    data-recurly="postal_code"
                    data-test="recurly-postal"
                    disabled={loading}
                    onChange={e => updateZipCode(e)}
                    placeholder={translate('Enter Here')}
                    type="text"
                    value={userZipCode}
                  />
                </div>
              </InputRow>
            </FormInnerWrap>
            {planCode === 'all_access_annual' ? (
              <InputRow full>
                <CheckBox
                  data-recurly="auto_renew"
                  data-test="recurly-auto-renew"
                  disabled={loading}
                  onChange={updateAutoRenew}
                  type="checkbox"
                >
                  <label>
                    {translate(
                      'Yes, please automatically renew my subscription annually (subject to {termsLink})',
                      {
                        termsLink: (
                          <TermsLink onClick={openOfferModal}>
                            {translate('terms & conditions')}
                          </TermsLink>
                        ),
                      },
                    )}
                  </label>
                </CheckBox>
              </InputRow>
            ) : null}
            <TermsofUseText>
              <p>
                {translate(
                  'Charges will appear on your credit card statement as "IHEART BILLING"',
                )}
              </p>
              <p>
                {translate(
                  'You can cancel at any time by going to "Settings" and following the cancellation instructions. By clicking "Subscribe" below, you agree to the conditions above and the iHeart ',
                )}
                <a
                  href="/content/terms-of-use/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {translate('Terms of Use')}.
                </a>
              </p>
            </TermsofUseText>

            <CheckoutButtonWrap>
              <FilledButton
                data-test="checkout-button"
                isBlock
                styleType="cta"
                tabIndex={3}
                type="submit"
              >
                <span>
                  {loading ? translate('Processing') : translate('Subscribe')}
                </span>
                {loading ? (
                  <SpinnerWrapper>
                    <LoadingSpinner
                      loading
                      strokeWidth={2}
                      style={{
                        background: 'transparent',
                        bufferColor: 'transparent',
                        spinnerFill: 'transparent',
                        spinnerStroke: colors.white.primary!,
                        textColor: 'transparent',
                      }}
                    />
                  </SpinnerWrapper>
                ) : null}
              </FilledButton>
            </CheckoutButtonWrap>
          </form>
        </RecurlyFormWrap>
      </RecurlyHOC>
      <Modal>
        <Modal.Container>
          <Modal.Content>
            <TermsModal close={toggle} />
          </Modal.Content>
        </Modal.Container>
      </Modal>
    </>
  );
};

export default RecurlySubscribe;
